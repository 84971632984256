import { Classification } from '@asd-stan/standard/domain/classification.entity';
import { IcsCode } from '@asd-stan/standard/domain/ics-code.entity';
import { Originator } from '@asd-stan/standard/domain/standard-detailed.entity';
import { Tag } from '@asd-stan/standard/domain/tag.entity';
import { makeAutoObservable } from 'mobx';
import { TransactionFromAPI } from '@asd-stan/standard/domain/standard-stage.entity';

export interface StandardArgs {
	id: number;
	stanNumber: string;
	registrationNumber: string;
	form: string;
	edition: string;
	cenWiNumber: string;
	statuses: string[];
	localizedTitle: string;
	tags?: Tag[];
	classifications?: Classification[];
	icsCodes?: IcsCode[];
	isDraft: boolean;
	transaction: TransactionFromAPI;
	originator?: Originator;
}

export class Standard {
	private _id: number;
	private _stanNumber: string;
	private _registrationNumber: string;
	private _form: string;
	private _edition: string;
	private _cenWiNumber: string;
	private _statuses: string[];
	private _localizedTitle: string;
	private _isDraft: boolean;
	private _tags: Tag[];
	private _classifications: Classification[];
	private _icsCodes: IcsCode[];
	private _originatorId?: string | null;
	private _transaction: TransactionFromAPI | null;

	constructor(args: StandardArgs) {
		makeAutoObservable(this);

		this._id = args.id;
		this._stanNumber = args.stanNumber;
		this._registrationNumber = args.registrationNumber;
		this._form = args.form;
		this._edition = args.edition;
		this._cenWiNumber = args.cenWiNumber;
		this._statuses = args.statuses;
		this._localizedTitle = args.localizedTitle;
		this._isDraft = args.isDraft;
		this._tags = args.tags || [];
		this._classifications = args.classifications || [];
		this._icsCodes = args.icsCodes || [];
		this._originatorId = args.originator?.userId;
		this._transaction = args.transaction;
	}

	//getters

	get id() {
		return this._id;
	}

	get stanNumber() {
		return this._stanNumber;
	}

	get registrationNumber() {
		return this._registrationNumber;
	}

	get form() {
		return this._form;
	}

	get edition() {
		return this._edition;
	}

	get cenWiNumber() {
		return this._cenWiNumber;
	}

	get statuses() {
		return this._statuses;
	}

	get localizedTitle() {
		return this._localizedTitle;
	}

	get isDraft() {
		return this._isDraft;
	}

	get tags() {
		return this._tags;
	}

	get classifications() {
		return this._classifications;
	}

	get icsCodes() {
		return this._icsCodes;
	}

	get originatorId() {
		return this._originatorId;
	}

	get transaction() {
		return this._transaction;
	}

	// getters end
}
